
import * as React from "react";
import { Sticky, StickyContainer } from "react-sticky";
import { graphql } from "gatsby";
import { inject } from "mobx-react";

import { Gallery, GalleryFilterBar, Seo, IGalleryFilter } from "components";
import { PAGE_VIEW_EVENT, TraumwandPageComponent, TraumwandPageProps, getLanguageValue, getFluidImageSharp } from "utils";
import { SquidexGalleryConnection, SquidexGallery, SquidexGalleryEdge } from 'generated/gatsbyApiTypes';

export interface QueryData {
  gallery: SquidexGallery;
  galleries: SquidexGalleryConnection
}

export interface GalleryPageState {
  activeFilters: IGalleryFilter[];
}

@inject("visitorStore", "shopStore")
export default class GalleryPage extends TraumwandPageComponent<QueryData, unknown, GalleryPageState> {
  constructor(props: TraumwandPageProps<QueryData, unknown>) {
    super(props);
    this.state = {
      activeFilters: []
    }
  }
  componentWillMount() {
    this.props.visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "gallery"
    });
  }
  render() {
    const name = getLanguageValue(this.props.data.gallery.name);
    const intro = getLanguageValue(this.props.data.gallery.headertext);
    const galleryId = this.props.data.gallery.galleryId ? this.props.data.gallery.galleryId.iv : undefined;
    const gallery = galleryId ? this.props.shopStore.current.imageGalleries.filter((g) => g.id === galleryId)[0] : undefined;
    const otherGalleries = this.props.data.galleries.nodes;

    return (
      <>
        <Seo subTitle={name} />
        <h1>{name}</h1>
        <p className="pa0">{intro}</p>
        <StickyContainer>
          <Sticky bottomOffset={0}>
            {({ style }) => (
              <GalleryFilterBar
                gallery={this.props.data.gallery}
                style={style}
                onFilterChange={this.onFilterChange}
                navigate={this.props.navigate}
              />
            )}
          </Sticky>
          <Gallery
            gallery={gallery}
            nodes={this.props.data.gallery.galleryItems}
            navigate={this.props.navigate}
            filters={this.state.activeFilters}
          />
          <div className="mb4"/>
        </StickyContainer>
      </>
    )
  }
  onFilterChange = (filters: IGalleryFilter[]) => {
    this.setState({ activeFilters: filters });
  }
}

export const query = graphql`
query($id: String!) {
  galleries: allSquidexGallery(limit: 5, sort: {order:DESC, fields: priority___iv}) {
    nodes {
      id
      name {
        de
        nl
        en 
      }
      slug {
        iv
      }
    }
  }
  gallery: squidexGallery(id: {eq: $id}) {
    name {
      de
      nl
      en 
    }
    headertext {
      de
      nl
      en
    }
    galleryId {
      iv
    }
    galleryItems {
      id
      name {
        iv
      }
      description {
        iv
      }
      tags {
        de {
          name
          value
        }
        nl {
          name
          value
        }
        en {
          name
          value
        }
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
          original {
            src
            width
            height
          }
        }
      }
    }
  }
}
`
